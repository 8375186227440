.active {
  color: black;
}

.homeLogo {
  object-fit: contain;
  object-position: center;
  width: 70px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #616AF6;
  color: white;
  position: sticky;
  top: 0;
  z-index: 999999;
}

nav .title {
  margin: 0.5rem 1rem;
}

nav ul {
  display: flex;
  padding: 5px 25px;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  font-feature-settings: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-size: 20px;
  text-decoration: none;
  color: white;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  text-align: right;
  line-height: 45px;
  padding: 0 10px;
  display: inline-block;
}

nav ul li a:not(.active):hover {
  color: rgb(236, 210, 73);
}

nav .menu {
  display: none;
  position: absolute;
  top: 2rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.2rem;
}


@media (max-width: 480px) {
  nav .menu {
    display: flex;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  nav ul.open {
    display: flex;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }
}
