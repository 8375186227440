.footer {
  left: 0;
  bottom: 0;
  background-color: rgba(2, 0, 36, 1);
  color: white;
  text-align: center;
  z-index: inherit;
}

.tryitBg {
  background: rgb(8, 13, 105);
  background: linear-gradient(180deg, rgba(8, 13, 105, 1) 37%, rgba(2, 0, 36, 1) 55%);
}

.df {
  display: flex;
}

.dfjc {
  display: flex;
  justify-content: center;
}

.dfcolumn {
  display: flex;
  flex-direction: column;
}

.dfcol {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.dfcolaround {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-around !important;
}

.dfcolcenter {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.dfrow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.dfrowS {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.dfrowC {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.dfrowE {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}

.quote {
  color: #4F4F4F;
  font-family: raleway-semibold, raleway, sans-serif !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  font-style: italic !important;
  line-height: 1.6 !important;
}

.ma {
  margin: auto !important;
}

.mz {
  margin: 0;
}
.mT {
  margin-top: 0 !important;
}
.mR {
  margin-right: 0 !important;
}
.mB {
  margin-bottom: 0 !important;
}
.mL {
  margin-left: 0 !important;
}

.m20 {
  margin: 20px;
}

.m30 {
  margin: 30px;
}

.m15 {
  margin: 15px;
}

.mt10 {
  margin-top: 10px;
}

.mL10 {
  margin-left: 10px !important;
}
.mL20 {
  margin-left: 10px !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.title {
  color: #606AF6;
  font-size: 60px;
  text-align: right;
  font-weight: 400;
  margin: 0 30px;
}

.title2 {
  color: #606AF6;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  width: calc(100% - 60px);
  margin: 30px;
}

.subtitle {
  color: #4F4F4F;
  font-size: 30px;
  text-align: right;
  margin-right: 20px;
  text-wrap: balance;
}

.para {
  color: #4F4F4F;
  font-size: 20px;
  font-weight: 400;
  text-align: right;
  margin-right: 30px;
  text-wrap: balance;
}

.image {
  display: block;
  width: 100%;
  height: 65vh;
}

.overlay {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  background-color: transparent;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .5s ease;
}

.pz {
  padding: 0;
}

.p30 {
  padding: 30px;
}

.para2 {
  color: #4F4F4F;
  font-size: 22px;
  font-style: normal;
  line-height: 1.6;
  font-stretch: 100%;
  font-weight: 300;
  letter-spacing: normal;
}

.bgPurple {
  background-color: #eceefd;
}

.bgWhite {
  background-color: #fafafd;
  color: inherit;
}

.try {
  background-color: rgb(236, 238, 253);
  border: rgb(37, 49, 141);
  color: rgb(85, 26, 139);
  cursor: pointer !important;
  border-top-style: solid;
  border-top-width: 0px;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  width: 150px;
  transition: all 0.3s ease-in-out 0s, visibility 0s;
  --transition: all 0.3s ease-in-out 0s, visibility 0s;
}

.try:hover {
  background-color: blue;
  color: white;
}

.imgbackground1 {
  background-image: url(../images/sidebackground1.png);
  object-fit: cover;
  object-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.imgbackground2 {
  background-image: url(../images/sidebackground.png);
  object-fit: cover;
  object-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.addressBg {
  background-image: url(../images/sidebackground2.png);
  height: 500px;
  width: 80%;
  object-fit: cover;
  object-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.learn {
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
}

.featurePara {
  font-size: 18px;
  font-stretch: 100%;
  font-weight: 300;
  text-align: justify;
  font-family: inherit;
}

.featureHeading {
  font-family: poppins-semibold, poppins, sans-serif;
  font-size: 26px;
  font-stretch: 100%;
  font-weight: 600;
}

.libutton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  text-decoration: none !important;
  color: #ffffff !important;
  width: 200px;
  height: 32px;
  border-radius: 8px;
  background-color: #0A66C2;
}
